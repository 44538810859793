<template>
  <div class="pa-2 ma-2">
    <v-layout>
      <v-btn color="primary"  @click="$router.push('/hostel')" tile outlined class="mx-2"> <v-icon>mdi-arrow-left</v-icon> </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" tile outlined class="mx-2" @click="drawer = true"> Add </v-btn>
    </v-layout>
    <v-layout row wrap class="mt-3" v-if="hostels.length">
      <v-flex lg3 sm12 v-for="(item,index) in hostels" :key=" 'basic-top-nav' + index"  @click="$router.push(`/hostel/manage/${item._id}`)">
        <social-widget top-nav v-bind="item" class="px-2"></social-widget>
      </v-flex>
    </v-layout>
    <div v-else>
      <no-data />
    </div>
    <v-dialog v-model="drawer" absolute bottom temporary right width="500px">
      <v-card elevation="0" height="100%">
        <v-card-title class="primary white--text px-4">
          <span class="title"> {{ modelObj._id ? 'Update Hostel' : 'Add Hostel'}} </span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive"></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NoData from '../../components/NoData.vue'
export default {
  data () {
    return {
      colors: ['#ba234b','#e57b09','teal', '#a51288'],
      drawer: false,
      modelObj: {},
      hostels: [ ],
    }
  },
  components: {
    'social-widget': () => import ('@/components/SocialWidget.vue'),
    'no-data': () => import ('@/components/NoData.vue'),
    
  
    NoData},
  mounted () {
    this.getListHandler()
  },  
  methods: {
     getListHandler () {
      this.$_execute('get', 'hostels').then(response => {
        response.data.map(x => {
          x.dark = true
          x.icon = 'mdi-city'
          x.color = this.colors[Math.floor(Math.random() * this.colors.length)]
        })
        this.hostels = response.data
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `hostels/${this.modelObj._id}` : `/hostels`
      let method = this.modelObj._id ? `put` : `post`
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `hostels/${id}`).then(response => {
        this.drawer = true
        let model = response.data[0]
        this.modelObj = model
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'hostels',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>

<style>

</style>